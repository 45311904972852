<template>
  <a
    :href="href"
    :target="target"
    class="bg-gray-200 hover:bg-gray-300 text-black px-6 py-3 rounded inline-block text-center cursor-pointer"
  >
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      default: "_blank",
    },
  },
};
</script>