<template>
  <div class="fixed bg-black bg-opacity-70 inset-0" @click="$emit('close')">
    <i
      v-if="images.length > 1"
      class="fas fa-angle-left text-white shadow-lg fixed left-6 top-1/2 -translate-y-1/2 z-10 text-4xl cursor-pointer"
      title="Previous"
      @click.stop="previous"
    ></i>
    <i
      v-if="images.length > 1"
      class="fas fa-angle-right text-white shadow-lg fixed right-6 top-1/2 -translate-y-1/2 z-10 text-4xl cursor-pointer"
      title="Next"
      @click.stop="next"
    ></i>
    <i
      v-if="images.length > 1"
      class="fas fa-times-circle text-white shadow-lg fixed right-6 top-6 z-10 text-2xl cursor-pointer"
      title="Close"
      @click.stop="$emit('close')"
    ></i>
    <div class="flex h-screen justify-center items-center p-6">
      <img
        v-if="images[i].thumb"
        :src="images[i].url"
        class="shadow-lg max-h-full max-w-full select-none"
        @click.stop
      />
      <secondary-link v-else :href="images[i].url">
        <i class="fas fa-download"></i> {{ images[i].filename }}
      </secondary-link>
    </div>
  </div>
</template>

<script>
import SecondaryLink from "./SecondaryLink";

export default {
  components: {
    SecondaryLink,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      i: this.index,
    };
  },
  created() {
    document.body.classList.add("overflow-hidden");
    window.addEventListener("keydown", this.keyDown);
  },
  beforeDestroy() {
    document.body.classList.remove("overflow-hidden");
    window.removeEventListener("keydown", this.keyDown);
  },
  methods: {
    next() {
      let next = this.i + 1;
      if (next >= this.images.length) {
        next = 0;
      }

      this.i = next;
    },
    previous() {
      let previous = this.i - 1;
      if (previous < 0) {
        previous = this.images.length - 1;
      }

      this.i = previous;
    },
    keyDown(e) {
      // Esc
      if (e.keyCode === 27) {
        this.$emit("close");
      }

      // Left
      if (e.keyCode === 37) {
        this.previous();
      }

      // Right
      if (e.keyCode === 39) {
        this.next();
      }
    },
  },
};
</script>